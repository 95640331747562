/* You can add global styles to this file, and also import other style files */

body{
  padding:0;
  margin:0;
  font-family: 'Roboto', sans-serif;
  background:#fff;
}
body a{
  transition:0.5s all;
  -webkit-transition:0.5s all;
  -moz-transition:0.5s all;
  -o-transition:0.5s all;
  -ms-transition:0.5s all;
  color: #ccc;
}

.header{
  background:#ffffff;
  padding:1em 0;
  border-bottom:5px solid #900909;
}
.logo{
  float:left;
  margin-top: 13px;
  max-height: 200px;
  max-width: 200px;
  @media screen and (max-width:1200px) {
    margin-top: 10px;
    max-height: 110px;
    max-width: 110px;
  }
  img {
    max-width: 190px;
    @media screen and (max-width:1200px) {
      max-width: 100px;
    }
  }
}
.header-right{
  float:right;
}
.header-right h4 {
  color: #ffffff;
  font-size: 2.1em;
  font-weight: 700;
  text-align: right;
  margin-bottom: 31px;
}
i.phone {
  width: 35px;
  height: 35px;
  background: url('assets/images/img-sprite.png') no-repeat 0px 0px;
  display: inline-block;
  vertical-align: sub;
  margin-right: 5px;
}
.top-menu ul li{
  display:inline-block;
  margin: 0 0.8em;
}
.top-menu ul li:nth-child(6) {
  margin-right: 0;
}
.top-menu ul li a:nth-child(6) {
  padding-right:0;
}
.top-menu ul li a{
  text-decoration: none;
  color: #ccc;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.1em;
  padding: 10px 5px;
}
.top-menu ul li a.active{
  border-bottom:5px solid #900909;
  color:#900909;
}
.top-menu ul li a:hover{
  border-bottom:5px solid #900909;
  color:#900909;
}
.banner{
  background:url('assets/images/databasebanner.jpg') no-repeat 0px 0px;
  background-size:cover;
  min-height:494px;
  position:relative;
}
.banner-info h2{
  color: #fff;
  font-size: 2.8em;
  font-weight: 300;
  letter-spacing: 3px;
  margin-bottom: 0.7em;
}
.banner-info p{
  color: #fff;
  font-size: 1.5em;
  font-weight: 400;
  line-height:1.5em;
}

.banner-info {
  background: rgba(34, 100, 100, 0.7);
  width: 40%;
  position: absolute;
  top: 17%;
  left: 14%;
  padding: 2.5em 2em;
}

.subscribe-section{
  background: url('assets/images/subscribe-bg.jpg') no-repeat 0px 0px;
  background-size:cover;
  min-height: 250px;
}
.services-section{
  padding:4em 0;
}
.services-section h3 {
  color: rgb(57, 54, 50);
  font-size: 2.7em;
  font-weight: 300;
  letter-spacing:2px;
  margin-bottom:0.8em;
}
.services-section-grid1-top {
  background: #900909;
  padding: 15px 25px;
  margin-bottom: 15px;
}
.services-section h4 {
  color: #fff;
  font-weight: 300;
  font-size: 1.9em;
  letter-spacing: 2px;
}
.services-section p {
  color: #fff;
  font-weight: 300;
  font-size: 1.05em;
  line-height:1.4em;
  margin:8px 0 15px 0;
}

i.user{
  width: 70px;
  height: 70px;
  background: url('assets/images/img-sprite.png') no-repeat -2px -137px;
  display: inline-block;
  margin-bottom: 2em;
}
i.global{
  width: 70px;
  height: 70px;
  background: url('assets/images/img-sprite.png') no-repeat -78px -78px;
  display: inline-block;
  margin-bottom: 2em;
}
.arrow1 {
  margin-top: 15px;
}
.arrow2 {
  margin-top: 22px;
}
.arrow3 {
  margin-top: 50px;
}
.arrow4 {
  margin-top: 18px;
}
.arrow5 {
  margin-top: 18px;
}
.icons{
  margin-top:2em;
}
.icons2{
  margin-top:7em;
}
.services-section-grid1-bottom {
  background: #393632;
  padding: 15px 25px;
}
.services-section-grid2 {
  background: #226464;
  padding: 15px 25px;
  width: 33.333%;
}
.services-section-grid3-top {
  background: #393632;
  padding: 15px 25px;
  margin-bottom: 15px;
}
.services-section-grid3-bottom {
  background: #900909;
  padding: 15px 25px;
}
.services-section-grid1 {
  width: 33.333%;
  padding: 0 15px 0 0;
}
.services-section-grid3 {
  width: 33.333%;
  padding: 0 0 0 15px;
}
.icon-left{
  float:left;
}
.icon-right{
  float:right;
}
.subscribe h3 {
  color: #fff;
  font-size: 2.8em;
  font-weight: 300;
  letter-spacing: 2px;
  margin-bottom: 1em;
}
.subscribe input[type="text"] {
  width: 43%;
  color: #fff;
  font-size: 15px;
  padding: 12px;
  border: none;
  outline: none;
  background: #226464;
  border:2px solid #fff;
}
.subscribe a{
  text-decoration: none;
  border: 2px solid #fff;
  background: #226464;
  color: #fff;
  font-size: 14px;
  letter-spacing: 2px;
  margin-left: -6px;
  padding: 13px 30px;
}
.subscribe a:hover {
  background: #000;
}
.subscribe {
  text-align: right;
}
.book {
  text-align: center;
}
.subscribe-section-grids{
  padding-top:3.5em;
}
.our-clients-head h3 {
  color: rgb(57, 54, 50);
  font-size: 2.9em;
  font-weight: 300;
  letter-spacing: 2px;
  margin-bottom: 1em;
}
/*--clients--*/
#flexiselDemo1, #flexiselDemo2, #flexiselDemo3 {
  display: none;
}
.nbs-flexisel-container {
  position: relative;
  max-width: 100%;
}
.nbs-flexisel-ul {
  position: relative;
  width: 99999px;
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  text-align: center;
}
.nbs-flexisel-inner {
  overflow: hidden;
}
.nbs-flexisel-item {
  float: left;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  position: relative;
}
.nbs-flexisel-item > img {
  position: relative;
  max-width:300px;
  max-height:100px;
}
/*** Navigation ***/
.nbs-flexisel-nav-left, .nbs-flexisel-nav-right {
  width: 40px;
  height: 35px;
  position: absolute;
  cursor: pointer;
  z-index: 100;
  margin-top:9em;
}
.nbs-flexisel-nav-left {
  left: 24em;
  background: url('assets/images/img-sprite.png') no-repeat -75px -176px;
}
.nbs-flexisel-nav-right {
  right: 24em;
  background: url('assets/images/img-sprite.png') no-repeat -114px -176px;
}
/********************/
/*-----*/

.nbs-flexisel-container {
  position:relative;
  max-width:100%;
}
.nbs-flexisel-ul {
  position:relative;
  width:99999px;
  margin:0px;
  padding:0px;
  list-style-type:none;
  text-align:center;
}

.nbs-flexisel-inner {
  overflow:hidden;
  float:left;
  width:100%;
}
.nbs-flexisel-item {
  float:left;
  margin:0px;
  padding:0px;
  position:relative;
  cursor:pointer;
}
.nbs-flexisel-item img {
  /*    width: 65%; */
  position: relative;
}
.our-clients {
  padding: 3.5em 0;
}
.client-text p {
  color: #dee5d6;
  font-size: 1.75em;
  font-weight: 300;
  line-height: 1.6em;
}
.testimonials-section{
  background: url('assets/images/t-bg.jpg') no-repeat 0px 0px;
  background-size:cover;
  min-height:494px
}
.testimonials-section-head h3{
  color:#8a8a8a;
  font-size: 2.8em;
  font-weight: 300;
  letter-spacing: 2px;
}
.testimonials-section-head {
  margin: 3em 0;
}
.text{
  background:#fff;
  padding:12px;
}
.text h4{
  color:#333333;
  font-size:1.3em;
  font-weight:500;
  margin-bottom:5px;
  text-transform:uppercase;
}
.text p{
  color:#585858;
  font-size:1em;
  text-transform:uppercase;
}
.description {
  background: rgba(57, 54, 50, 0.67);
  padding: 3em 0 0 0;
  min-height: 305px;
  width: 100%;
}
.client-text {
  padding: 0 8em;
}
.member1 {
  width: 23%;
  float: left;
  padding:0;
}
.member1  img{
  width:100%;
}
.contact-section {
  padding: 3em 0;
}
.contact-section h3 {
  color: rgb(57, 54, 50);
  font-size: 2.9em;
  font-weight: 300;
  letter-spacing: 2px;
  margin-bottom: 1em;
}
.contact-top p {
  color: #000;
  font-size: 1.3em;
  font-weight: 500;
}
.contact-section {
  padding: 4em 0;
}
.contact-section-bottom {
  position: relative;
}
.contact-form {
  background: #1a1a1a;
  padding: 4.5em 3em;
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 3em;
}
.contact-form input[type="text"], .contact-form textarea {
  width: 100%;
  color: #fff;
  font-size: 16px;
  padding: 15px 15px;
  border: none;
  outline: none;
  margin: 0 0px 15px 0;
  background: #333333;
}
.contact-form textarea {
  resize: none;
  width: 98%;
  height: 7.5em;
}
.contact-form input[type="submit"] {
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;
  border: 1px solid #fff;
  padding: 9px 30px;
  outline: none;
  background: #1a1a1a;
  margin-top: 1em;
}
.contact-form input[type="submit"]:hover{
  background:#329cd6;
}
.map{
  position:relative;
  margin-top: 13em;
}
.map iframe{
  width:100%;
  min-height:500px;
}
.map-layer{
  background:#1a1a1a;
  min-height:500px;
}
.map-layer {
  background: rgba(26, 26, 26, 0.48);
  min-height: 505px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.footer{
  background:#1a1a1a;
  padding:1em 0;
}
.social-icons i{
  width: 25px;
  height: 25px;
  background: url('assets/images/img-sprite.png') no-repeat -75px -150px;
  display: inline-block;
}
.social-icons i:hover{
  opacity:0.5;
}
i.facebook{
  background-position: -75px -150px;
  margin:0 10px;
}
i.twitter{
  background-position: -100px -150px;
  margin:0 10px;
}
i.googleplus{
  background-position:-125px -150px;
  margin:0 10px;
}
i.youtube{
  background-position: -150px -150px;
  margin:0 10px;
}
i.linkedin{
  background-position: -175px -150px;
  margin:0 10px;
}
.copyright p{
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  margin-top: 1em;
}
.copyright p a{
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  margin-top: 1em;
}

/*-- services-page-starts-here --*/
.services_overview h3 {
  color: #fff;
  font-size: 2.7em;
  font-weight: 300;
  letter-spacing: 2px;
  margin-bottom: 0.8em;
}
.service_grid {
  padding: 0;
  width: 31.333%;
  margin-right: 3%;
}
.span_55{
  margin-right:0;
}
.service_grid h4 {
  color: #393632;
  font-size: 1.4em;
  font-weight: 500;
  text-transform: uppercase;
  margin: 20px 0 0 0;
}
.service_grid p{
  color: #E6E6E6;
  font-weight: 300;
  font-size: 1em;
  line-height: 1.8em;
  margin: 8px 0 0 0;
}
.services_overview{
  background:#226464;
  padding: 4em 0;
}
.s-map{
  margin-top:0;
}
/*-- about-page-starts-here --*/
.who_we_are,.latest-news {
  padding: 3em 0;
}
.grid_12 h3 {
  color: rgb(51, 51, 51);
  font-size: 2.7em;
  font-weight: 300;
  letter-spacing: 2px;
  margin-bottom: 0.8em;
}
.grid_12 p {
  color: #333333;
  font-weight: 400;
  font-size: 0.965em;
  line-height: 1.8em;
  margin: 8px 0 15px 0;
  max-width: 810px;
  margin: 0 auto 13px;

}
.grid_12 h5 {
  font-size: 1.11em;
  color: #1f3e4f;
  line-height: 1.6em;
  margin: 0 auto 13px;
  max-width: 860px;
  font-weight:600;
}
.grid_5{
  width:35%;
  margin-left:11%;
  margin-right:4%;
  float:left;
  margin-top:2%;
}
.grid_6{
  width:35%;
  margin-right:11%;
  margin-left:4%;
  float:left;
  margin-top:2%;
}
.grid_5 img,.grid_6 img{
  width:100%;
}
.why_choose_us {
  padding: 3em 0;
  background: #226464;
}
.grid_4 span {
  margin-top: 15px;
  display: inline-block;
  width: 78px;
  height: 78px;
  background: #900909;
  color: #e3e1e1;
  text-align: center;
  font-size: 24px;
  line-height: 78px;
  border-radius: 50%;
  position: relative;
}
.why_choose_us h3 {
  color: #fff;
  font-size: 2.7em;
  font-weight: 300;
  letter-spacing: 2px;
  margin-bottom: 0.8em;
}
.grid_4 p{
  color: #fff;
  font-weight: 400;
  font-size: 0.965em;
  line-height: 1.8em;
  margin: 15px 0 0 0;
}

/* General button style (reset) */
/*
.btn {
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: none;
  padding: 0.4em 1.5em;
  display: inline-block;
  text-transform: uppercase;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  border-radius:0px !important;
  text-decoration: none;
}

.btn:after {
  content: '';
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.btn {
  background: #900909;
  color: #ffffff;
  border: none;
  font-size: 1.1em;
  font-weight: 400;
  padding:8px 20px;
  border-radius: 0;
  text-align: center;
  text-transform:capitalize;
  z-index:1;
}
.btn:hover {
  background: #ffffff;
  color: #900909;
}
a.btn1{
  font-family: inherit;
  background: none;
  padding:0.8em 3em;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  border-radius:0px !important;
  text-decoration: none;
  font-size: 1.8em;
  border: 2px solid #fff;
  font-weight: 900;
  color: #fff;
}
a.btn1:after {
  content: '';
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
*/
/* Button 1c */
.btn-1c:after {
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background: #fff;
}

.btn-1c:hover,
.btn-1c:active {
  color:#ff0000;
}

.btn-1c:hover:after,
.btn-1c:active:after {
  height: 100%;
}
/* Button 1d */
.btn-1d {
  overflow: hidden;
}

.btn-1d:after {
  width: 0;
  height: 103%;
  top: 50%;
  left: 50%;
  background: rgba(92, 162, 204, 0.7);
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.btn-1d:hover,
.btn-1d:active {
  color: #fff;
}

.btn-1d:hover:after {
  width: 90%;
  opacity: 1;
}

.btn-1d:active:after {
  width: 101%;
  opacity: 1;
}
.latest-news h3{
  color: rgb(51, 51, 51);
  font-size: 2.7em;
  font-weight: 300;
  letter-spacing: 2px;
  margin-bottom: 0.8em;
}
.banner-info a{
  color:#fff;
  font-size:1.25em;
  font-weight:500;
  padding:10px 22px;
  border:2px solid #fff;
  text-decoration:none;
  text-transform: uppercase;
  margin-top: 2em;
  display: block;
  width: 38%;
  z-index:1;
  background:rgba(92, 162, 204, 0);
}

.element{
  background: #226464;
  padding-bottom:2em;
}
.grid_7 img{
  width:100%;
}
.element p {
  color: #333333;
  font-weight: 400;
  font-size: 0.965em;
  line-height: 1.8em;
  margin:10px 0 0px 0;
  padding: 0 10px;
}
.element h4{
  color: #1f3e4f;
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 39px;
  margin-bottom: 17px;
}

.collection-row {
  background-color: #226464;
  padding: 1em;
}

/*-- contact-page-starts-here --*/

.contact-form_grid {
  text-align: center;
}
.contact-form_grid input[type="text"],.contact-form_grid textarea {
  display: block;
  width: 99%;
  padding:12px;
  outline: none;
  font-size: 14px;
  font-weight:bold;
  color: #D6D5D5;
  background: #FFF;
  border: none;
  border-radius: 0.3em;
  -webkit-border-radius: 0.3em;
  -moz-border-radius: 0.3em;
  -o-border-radius: 0.3em;
  margin-bottom:1em;
  font-family:'Open Sans', sans-serif;
}
.contact-form_grid input[type="text"]:focus,.contact-form_grid textarea:focus {
  color:#ADADAD;
}
.contact-form_grid textarea {
  height:170px;
  resize:none;
}
.contact-form_grid input[type="submit"] {
  display:block;
  font-size:18px;
  font-weight:600;
  color:#FFF;
  text-transform:uppercase;
  padding:12px 0px;
  width:100%;
  background:#900909;
  border-radius:5px;
  -webkit-border-radius:5px;
  -moz-border-radius:5px;
  -o-border-radius:5px;
  border:none;
  border-bottom:4px solid #4b0505;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -webkit-apperance:none;
  font-family:'Open Sans', sans-serif;
  outline:none;
}
.contact-form_grid input[type="submit"]:hover {
  background:#900909;
  border-bottom:4px solid #4b0505;
}
.contact-form_grid input[type="submit"]:active {
  position:relative;
  bottom:-2px;
}
.contact_right {
  background: #f1f1f1;
  padding: 5%;
}
.contact.about-desc {
  padding: 4em 0;
}
.contact.about-desc h3{
  color: rgb(51, 51, 51);
  font-size: 2.7em;
  font-weight: 300;
  letter-spacing: 2px;
  margin-bottom: 1.5em;
  text-align:center;
}
.contact_address {
  margin: 2em 0;
}
.contact_grid h5 {
  color: #900909;
  font-size:1.5em;
}
.contact_grid p {
  color:#999;
  line-height: 1.8em;
}
p.m_6 {
  color: #333333;
  font-weight: 400;
  font-size: 0.965em;
  line-height: 1.8em;
  margin: 8px 0 15px 0;
  opacity: 0.7;
}
p.m_7 {
  color: #333333;
  font-weight: 400;
  font-size: 0.965em;
  line-height: 1.8em;
  margin: 8px 0 15px 0;
  opacity: 0.7;
}
.contact_left h4{
  font-size:2em;
  font-weight:400;
  color:#900909;
  text-transform: uppercase;
}
/*-- blog-page-starts-here --*/
.head_element {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 1px solid #e6e6e6;
  text-align: center;
  padding-top: 7px;
  margin-right: 20px;
  margin-bottom: 17px;
  float: left;
}
.grid_4{

}
.alpha1{
  width:40%;
  margin-right:3%;
  float:left;
}
.alpha1 img{
  width:100%;
}
.head_element span {
  color: #900909;
  font-size: 1.8em;
  display: block;
  margin: 0 auto;
  text-transform: uppercase;
}
.sub{
  float:left;
  margin-top: 1.3em;
}
a.readblog{
  color: #1f3e4f;
  font-size: 1.6em;
  text-transform:uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom:3px;
  text-decoration:none;
}
.sub p{
  color: #1f3e4f;
  font-size:1em;
  font-weight:300;
}
.sub a:hover{
  color:#900909;
}
article.clearfix {
  margin: 2em 0 3em 0;
}
.blog-section {
  padding: 4em 0;
}
h2.with-line {
  color: rgb(51, 51, 51);
  font-size: 2.7em;
  font-weight: 300;
  letter-spacing: 2px;
  margin-bottom: 0.8em;
  text-align: center;
}
.right_column h2 {
  text-align: left;
  font-size: 2.3em;
  margin: 1em 0 0.4em 0;
}
.grid_b4 h5{
  font-size: 1em;
  color: #1f3e4f;
  line-height: 1.8em;
}
.left_column {
  width: 65%;
  padding: 0 50px 0 0;
}
.grid_b4 p {
  color: #333333;
  font-weight: 400;
  font-size: 0.9em;
  line-height: 1.9em;
  margin: 15px 0 27px 0;
  opacity: 0.6;
}
.right_column li {
  line-height: 14px;
  padding: 7px 0 7px 18px;
  background: url('assets/images/bullet.png') left 16px no-repeat;
  font-size: 13px;
  list-style-type:none;
}
.right_column li a {
  color: #333333;
  font-weight: 400;
  font-size: 0.965em;
  line-height: 1.8em;
  opacity: 0.6;
  text-decoration:none;
}
.right_column li a:hover{
  color:#900909;
  padding-left:10px;
}
.grid_b4 {
  float: left;
  width: 56%;
}
/*** Page numbers ***/
.content-pagenation{
  text-align:center;
}
.content-pagenation li {
  display: inline-block;
}
.content-pagenation li a {
  color:#900909;
  font-size: 1em;
  background: #FFF;
  padding: 10px 12px;
  border: 1px solid #900909;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .5s;
}
.content-pagenation li a:hover{
  background:#900909;
  color:#FFF;
}
/*-- error-page --*/
.error-page{
  padding:2em 0 5em 0;
}
.error-404 {
  padding-top: 8em;
  min-height: 650px;
}
.error-404 h2{
  font-size:18em;
  font-weight:500;
  color:#900909;
  margin:0;
  padding:0;
  text-transform:uppercase;
}
.error-404 p{
  color: #4f4844;
  margin: 0;
  font-size: 1.4em;
  text-transform: uppercase;
  margin-top: 1em;
}
a.b-home{
  background:#272e34;
  padding:0.8em 3em;
  display:inline-block;
  color:#FFF;
  text-decoration:none;
  margin-top:1em;
}
a.b-home:hover{
  background:#900909;
}
article.attach {
  margin-bottom: 1em;
}
p.text1 {
  color: #333333;
  font-weight: 400;
  font-size: 0.9em;
  line-height: 1.9em;
  /*	margin: 15px 0 27px 0; */
  opacity: 0.6;
}
#commentform input[type="text"] {
  padding: 8px 15px;
  width: 60%;
  color: #444;
  font-size: 1em;
  border: 1px solid #eee;
  background: #eee;
  outline: none;
  display: block;
}
.leave h4 {
  color: #1f3e4f;
  font-size: 1.8em;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 12px;
}
#commentform label {
  display: block;
  font-size: 1em;
  color: #888;
}
#commentform span {
  color: #A60000;
  position: absolute;
}
#commentform input[type="text"] {
  padding: 8px 15px;
  width: 60%;
  color: #444;
  font-size: 1em;
  border: 1px solid #eee;
  background: #eee;
  outline: none;
  display: block;
}
#commentform textarea {
  padding: 13px 20px;
  width:60%;
  color: #444;
  font-size:0.85em;
  outline: none;
  height:150px;
  display: block;
  resize: none;
  font-weight:400;
  border: 1px solid #eee;
  background: #eee;
}
#commentform input[type="submit"] {
  padding: 12px 50px;
  color: #FFF;
  background: #444;
  outline: none;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-appearance: none;
  font-size: 1.2em;
  font-weight: 500;
}
#commentform input[type="submit"]:hover {
  background:#222;
}
.comments1 {
  margin-top: 3em;
}
#commentform p {
  position: relative;
  padding: 8px 0;
  font-size: 1em;
}
.comments-main {
  border: 1px solid #D5D4D4;
  padding: 17px 0px;
  margin: 15px 0px 40px 0;
}
.cmnts-right a {
  padding: 6px 12px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  background: #999;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -o-transition: 0.5s all;
  outline: none;
}
.Archives {
  margin-bottom:3em;
}
.cmts-main-left img{
  border: 3px solid#ccc;
}
.cmnts-right a:hover{
  background: #333;
  text-decoration:none;
}
.cmts-main-right h5 {
  font-size: 1em;
  color: #4e4e4b;
  margin: 0px;
  font-weight: 600;
}
.comments1 h4 {
  color: #1f3e4f;
  font-size: 1.8em;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 12px;
}
.cmts-main-right p{
  color: #333333;
  font-weight: 400;
  font-size: 0.9em;
  line-height: 1.9em;
  opacity: 0.6;
  margin: 2px 0 15px 0px;
}
.cmnts-left p {
  font-size: 13px;
  color:#999;
  font-weight: 600;
  margin: 15px 0px;
}

/*-- responsive-design starts-here --*/

@media screen and (max-width: 1366px) {
  .banner-info {
    width: 45%;
    left: 8%;
    top: 13%;
    padding: 2em 1.8em 2em 2em;
  }
  .header-right h4 {
    font-size: 1.9em;
    margin-bottom: 25px;
  }
  .top-menu ul li a {
    font-size: 1.05em;
  }
  .banner-info h2 {
    font-size: 2.7em;
    letter-spacing: 2px;
    margin-bottom: 0.6em;
  }
  .banner-info p {
    font-size: 1.4em;
    line-height: 1.45em;
  }
  .banner-info a {
    font-size: 1.2em;
    padding: 9px 20px;
    width: 35%;
  }
  .banner {
    min-height: 421px;
  }
  .services-section {
    padding: 3em 0;
  }
  .subscribe-section {
    min-height: 200px;
  }
  .subscribe h3 {
    font-size: 2.6em;
    margin-bottom: 0.7em;
  }
  .subscribe-section-grids {
    padding-top: 2.2em;
  }
  .subscribe input[type="text"] {
    font-size: 14px;
    padding: 10px;
  }
  .subscribe a {
    font-size: 13px;
    padding: 11px 30px;
  }
  .book img {
    width: 55%;
  }
  .member1 {
    width: 16.7%;
  }
  .client-text p{
    font-size:1.5em;
  }
  .nbs-flexisel-nav-left, .nbs-flexisel-nav-right{
    margin-top:7em;
  }
  .description {
    min-height: 240px;
    width: 100%;
  }
  .testimonials-section {
    min-height: 417px;
  }
  .testimonials-section-head {
    margin: 2em 0 2.5em 0;
  }
  .nbs-flexisel-nav-left{
    left:30.5em;
  }
  .nbs-flexisel-nav-right{
    right:30.5em;
  }
  .map-layer {
    min-height: 455px;
  }
  .map iframe {
    min-height: 450px;
  }
  .contact-form {
    padding: 3.5em 3em;
  }
  .services_overview {
    padding: 3em 0;
  }
  .blog-section {
    padding: 3em 0 4em 0;
  }
  .contact.about-desc {
    padding: 3em 0;
  }
  .contact.about-desc h3 {
    margin-bottom: 1.2em;
  }
  .contact_left h4 {
    font-size: 1.85em;
  }
}
@media screen and (max-width: 1280px) {
  .map-layer {
    min-height: 405px;
  }
  .map iframe {
    min-height: 400px;
  }
  .banner-info {
    width: 44%;
    left: 4.5%;
    padding: 1.5em 1.5em 1.8em 1.5em;
  }
  .banner {
    min-height: 389px;
  }
  .banner-info h2 {
    font-size: 2.6em;
    letter-spacing: 1px;
    margin-bottom: 0.5em;
  }
  .banner-info p {
    font-size: 1.35em;
    line-height: 1.4em;
  }
  .banner-info a {
    font-size: 1.1em;
    margin-top: 1.8em;
  }
  .icons {
    margin-top: 1.5em;
  }
  .icons2 {
    margin-top: 6em;
  }
  .subscribe h3 {
    font-size: 2.4em;
    margin-bottom: 0.6em;
  }
  .subscribe-section-grids {
    padding-top: 1.8em;
  }
  .book img {
    width: 50%;
  }
  .subscribe-section {
    min-height: 171px;
  }
  .our-clients {
    padding: 2.5em 0;
  }
  .nbs-flexisel-item img{
    /*	width:60%; */
  }
  .testimonials-section {
    min-height: 390px;
  }
  .contact-section {
    padding: 2em 0;
  }
  .who_we_are, .latest-news {
    padding: 2em 0 3em 0;
  }
}
@media screen and (max-width: 1024px) {
  .header-right h4 {
    font-size: 1.55em;
    margin-bottom: 20px;
  }
  i.phone {
    width: 25px;
    height: 25px;
    background-size: 450px;
  }
  .top-menu ul li a {
    font-size: 1em;
    padding: 5px 4px;
  }
  .top-menu ul li a.active {
    border-bottom: 4px solid #900909;
  }
  .top-menu ul li a:hover {
    border-bottom: 4px solid #900909;
  }
  .header {
    padding: 0.5em 0;
  }
  .banner-info h2 {
    font-size: 2.15em;
    letter-spacing: 0px;
  }
  .banner-info p {
    font-size: 1.25em;
  }
  .banner-info a {
    font-size: 1em;
    margin-top: 1.6em;
    padding: 7px 15px;
    width: 39%;
  }
  .banner-info {
    top: 10%;
    left: 3.5%;
    padding: 1.3em 1.3em 1.6em 1.3em;
  }
  .banner {
    min-height: 315px;
  }
  .services-section {
    padding: 2em 0 3em 0;
  }
  .services-section h3 {
    font-size: 2.5em;
    letter-spacing: 0px;
    margin-bottom: 0.55em;
  }
  .services-section-grid1-top,.services-section-grid3-top{
    padding: 10px 15px;
    margin-bottom: 10px;
  }
  .services-section-grid1-bottom,.services-section-grid3-bottom,.services-section-grid2{
    padding: 10px 15px;
  }
  .services-section-grid1 {
    padding: 0 10px 0 0;
  }
  .services-section-grid3 {
    padding: 0 0 0 10px;
  }
  .services-section h4 {
    font-size: 1.8em;
    letter-spacing: 1px;
  }
  .icons2 {
    margin-top: 4.8em;
  }
  .subscribe h3 {
    font-size: 2.2em;
    letter-spacing: 1px;
  }
  .subscribe input[type="text"] {
    padding: 8px;
  }
  .subscribe a {
    padding: 9px 25px;
  }
  .subscribe-section-grids {
    padding-top: 1.6em;
  }
  .subscribe-section {
    min-height: 150px;
  }
  .our-clients-head h3 {
    font-size: 2.5em;
    letter-spacing: 1px;
    margin-bottom: 0.8em;
  }
  .nbs-flexisel-item img{
    /*	width:55%;  */
  }
  .description {
    min-height: 205px;
    width: 100%;
  }
  .member1 {
    width: 18%;
  }
  .client-text p{
    font-size:1.4em;
  }
  .client-text {
    padding: 0 3em;
  }
  .nbs-flexisel-nav-right{
    right: 24.5em;
  }
  .nbs-flexisel-nav-left{
    left: 24.5em;
  }
  .description {
    padding: 1.6em 0 0 0;
  }
  .nbs-flexisel-nav-left, .nbs-flexisel-nav-right {
    margin-top: 6em;
  }
  .testimonials-section-head h3 {
    font-size: 2.4em;
    letter-spacing: 1px;
  }
  .contact-section h3 {
    font-size: 2.5em;
    letter-spacing: 1px;
    margin-bottom: 0.8em;
  }
  .testimonials-section-head {
    margin: 1.5em 0 1.5em 0;
  }
  i.smartphone,i.user,i.global {
    margin-bottom: 1em;
  }
  .testimonials-section {
    min-height: 315px;
  }
  .text h4 {
    font-size: 1.1em;
    margin-bottom: 2px;
  }
  .text p {
    font-size: 0.95em;
  }
  .text {
    padding: 8px;
  }
  .contact-top p {
    font-size: 1.15em;
  }
  .footer {
    padding: 2em 0;
  }
  .map-layer {
    min-height: 355px;
  }
  .map iframe {
    min-height: 350px;
  }
  .contact-form input[type="text"], .contact-form textarea {
    font-size: 14px;
    padding: 12px 12px;
  }
  .contact-form {
    padding: 2.5em 3em;
  }
  .map {
    margin-top: 10em;
  }
  .error-404 {
    padding-top: 6em;
    min-height: 590px;
  }
  .grid_12 h3 {
    font-size: 2.6em;
    letter-spacing: 1px;
    margin-bottom: 0.5em;
  }
  .s-map {
    margin-top: 0;
  }
  .why_choose_us {
    padding: 2em 0 3em 0;
  }
  .why_choose_us h3,.latest-news h3 {
    font-size: 2.5em;
    letter-spacing: 1px;
    margin-bottom: 0.5em;
  }
  .element h4 {
    font-size: 17px;
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .element p {
    font-size: 0.95em;
    line-height: 1.6em;
  }
  .btn {
    font-size: 1em;
    padding: 7px 18px;
  }
  .error-404 h2 {
    font-size: 17em;
  }
  .services_overview h3 {
    font-size: 2.5em;
    letter-spacing: 1px;
    margin-bottom: 0.5em;
  }
  .services_overview {
    padding: 2em 0;
  }
  .service_grid h4 {
    font-size: 1.21em;
    margin: 15px 0 0 0;
  }
  .service_grid p {
    font-size: 0.95em;
    line-height: 1.7em;
    margin: 5px 0 20px 0;
  }
  p.m_6 {
    margin: 8px 0;
  }
  .contact.about-desc h3 {
    margin-bottom: 1em;
    font-size: 2.6em;
    letter-spacing: 1px;
  }
  .contact_left h4 {
    font-size: 1.75em;
  }
  .head_element span {
    font-size: 1.5em;
  }
  .head_element {
    width: 80px;
    height: 80px;
  }
  .sub h3 {
    font-size: 1.45em;
    letter-spacing: 1px;
    margin-bottom: 2px;
  }
  .sub p {
    font-size: 0.95em;
  }
  .grid_b4 h5 {
    line-height: 1.5em;
  }
  .grid_b4 p {
    line-height: 1.8em;
    margin: 5px 0 15px 0;
  }
  .blog-section {
    padding: 2em 0 3em 0;
  }
  article.clearfix {
    margin: 1em 0 3em 0;
  }
  h2.with-line {
    font-size: 2.65em;
    letter-spacing: 1px;
    margin-bottom: 0em;
  }
  .right_column h2 {
    font-size: 2.2em;
    margin: 1em 0 0.4em 0;
    margin-bottom: 0.4em;
  }
  .right_column li {
    padding: 5px 0 5px 18px;
    background: url('assets/images/bullet.png') left 13px no-repeat;
  }
  .left_column {
    padding: 0 25px 0 0;
  }
  p.text1 {
    /*		margin-top: -47px; */
  }
  .cmts-main-left {
    padding: 0 0 0 8px;
    width: 16.666%;
  }
  .cmts-main-left img{
    width:100%;
  }
  .cmts-main-right p {
    height: 63px;
    overflow: hidden;
    line-height: 1.7em;
  }
  .cmnts-left p {
    height: 24px;
    margin:0;
  }
  a.readblog {
    font-size: 1.4em;
  }
}
@media screen and (max-width: 800px) {
  .header-right h4 {
    display: none;
  }
  .banner-info {
    width: 68%;
  }
  .services-section-grid1-bottom,.services-section-grid3-bottom{
    width: 49%;
    float: left;
  }
  .services-section-grid1-top,.services-section-grid3-top{
    width: 49%;
    float: left;
    margin-right:2%;
    margin-bottom: 0;
  }
  .services-section-grid1,.services-section-grid3 {
    width: 100%;
    float: left;
  }
  .services-section-grid2{
    width:100%;
    float:left;
    margin: 2% 0;
  }
  .services-section-grid3 {
    padding: 0;
  }
  .services-section-grid1 {
    padding: 0;
  }
  .icons2 {
    margin-top: 2em;
  }
  .subscribe {
    width: 70%;
    float: left;
  }
  .book {
    width: 30%;
    float: left;
  }
  .book img {
    width: 70%;
  }
  .subscribe h3 {
    font-size: 1.8em;
    letter-spacing: 0px;
  }
  .subscribe a {
    padding: 7px 18px;
    border: 1px solid #fff;
  }
  .subscribe input[type="text"] {
    padding: 6px;
    border: 1px solid #fff;
  }
  .subscribe-section-grids {
    padding-top: 1.4em;
  }
  .subscribe-section {
    min-height: 125px;
  }
  .contact-section-grid {
    width: 33.333%;
    float: left;
  }
  .contact-top p {
    font-size: 1em;
  }
  .contact-left{
    width:50%;
    float:left;
  }
  .contact-right{
    width:50%;
    float:left;
  }
  .contact-form {
    padding: 2em 1em;
  }
  .contact-form input[type="submit"] {
    font-size: 14px;
    padding: 6px 25px;
    margin-top: 0.5em;
  }
  .map-layer {
    min-height: 305px;
  }
  .map iframe {
    min-height: 300px;
  }
  .copyright p {
    font-size: 15px;
    margin-top: 0.5em;
  }
  .contact-section h3 {
    font-size: 2.3em;
    letter-spacing: 0px;
  }
  .contact-form input[type="text"], .contact-form textarea {
    font-size: 13px;
    padding: 12px 10px;
  }
  .description {
    padding: 1.2em 0 0 0;
    float: left;
    width: 100%;
    min-height: 182px;
  }
  .member1 {
    width: 20%;
  }
  .client-text p{
    font-size:1.2em;
  }
  .testimonials-section-head h3 {
    font-size: 2.1em;
    letter-spacing: 0px;
  }
  .our-clients-head h3 {
    font-size: 2.3em;
    letter-spacing: 0px;
  }
  .banner-info h2 {
    font-size: 1.8em;
  }
  .banner-info p{
    font-size:1em;
  }
  .banner-info a {
    font-size: 0.95em;
    margin-top: 1.5em;
    padding: 7px 0px;
    border: 1px solid #fff;
    width: 42%;
  }
  .banner-info {
    width: 47%;
    padding: 15px 11px 20px 17px;
  }
  .banner {
    min-height: 240px;
  }
  .services-section h3 {
    font-size: 2.3em;
    margin-bottom: 0.5em;
  }
  .header {
    border-bottom: 3px solid #900909;
    padding:17px 0;
  }
  .text p {
    font-size: 0.875em;
  }
  .nbs-flexisel-nav-left, .nbs-flexisel-nav-right {
    margin-top: 5em;
  }
  .nbs-flexisel-nav-right {
    right: 17.5em;
  }
  .nbs-flexisel-nav-left {
    left: 17.5em;
  }
  .testimonials-section {
    min-height: 290px;
  }
  span.menu{
    width:28px;
    height:28px;
    background:url('assets/images/nav_on_white.png')no-repeat;
    display: inline-block;
    float:right;
    cursor: pointer;
    margin-top: -38px;
  }
  .top-menu{
    width:100%;
    display: none;
    text-align:center;
    background:#fff;
    padding:0;
    margin-top:10px;
  }
  .top-menu ul{
    float:none;
    width:100%;
  }
  .top-menu ul li{
    display:block;
    float: none;
  }
  .top-menu ul li a{
    color:#000;
    display: block;
    padding: 10px 0px;
    border-bottom: 3px solid #fff;
  }
  .top-menu ul li a.active{
    border-bottom: 3px solid #900909;
  }
  .top-menu ul li a:hover{
    border-bottom: 3px solid #900909;
  }
  .header-right {
    width: 100%;
  }
  .error-404 h2 {
    font-size: 15em;
  }
  .grid_12 h5 {
    font-size: 1.05em;
    height: 46px;
    overflow: hidden;
  }
  .grid_12 p {
    /*		height: 74px;  */
    overflow: hidden;
  }
  .grid_12 h3 {
    font-size: 2.5em;
    letter-spacing: 0px;
    margin-bottom: 0.4em;
  }
  .grid_7 {
    width: 33.333%;
    float: left;
    padding:0 8px;
  }
  .grid_4 {
    width: 33.333%;
    float: left;
    padding: 0 10px;
  }
  .grid_4 span {
    margin-top: 10px;
    width: 65px;
    height: 65px;
    font-size: 22px;
    line-height: 65px;
  }
  .grid_4 p {
    font-size: 0.9em;
  }
  .element p {
    margin: 0px 0 0px 0;
  }
  .element h4 {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .element {
    padding-bottom: 1.5em;
  }
  .why_choose_us h3, .latest-news h3 {
    font-size: 2.3em;
    letter-spacing: 0px;
  }
  .btn {
    font-size: 0.95em;
    padding: 5px 15px;
  }
  .left_column {
    width: 100%;
    padding: 0 0px 0 0;
  }
  .grid_b4 h5 {
    line-height: 1.8em;
  }
  .grid_b4 p {
    line-height: 1.9em;
    margin: 10px 0 20px 0;
  }
  p.text1 {
    /*		margin-top: -27px;  */
  }
  .service_grid {
    width: 32%;
    margin-right: 2%;
    float:left;
  }
  .span_55 {
    margin-right: 0;
  }
  .services_overview h3 {
    font-size: 2.3em;
    letter-spacing: 0px;
    margin-bottom: 0.6em;
  }
  .service_grid h4 {
    font-size: 0.94em;
    margin: 12px 0 0 0;
  }
  .service_grid p {
    margin: 5px 0 0px 0;
    height: 67px;
    overflow: hidden;
  }
  h2.with-line {
    font-size: 2.5em;
    letter-spacing: 0px;
  }
  .cmts-main-left {
    margin-right: 4%;
    float:left;
    padding: 0 0 0 15px;
  }
  .cmnts-left {
    float: left;
  }
  .contact_right {
    margin-top: 5%;
    padding: 4%;
  }
  .contact.about-desc h3 {
    margin-bottom: 0.8em;
    font-size: 2.5em;
    letter-spacing: 0px;
  }
  .contact.about-desc {
    padding: 2em 0;
  }
  .contact_left h4 {
    font-size: 1.65em;
  }
  .right_column h2 {
    font-size: 2em;
  }
}
@media screen and (max-width: 768px) {
  .banner {
    min-height: 234px;
  }
  .error-404 h2 {
    font-size: 14em;
  }
  .error-404 {
    padding-top: 5em;
    min-height: 530px;
  }
  span.menu {
    margin-top: -32px;
  }
}
@media screen and (max-width: 640px) {
  .banner-info {
    width: 50%;
    padding: 10px 10px 15px 10px;
    top: 8%;
    left: 2.5%;
  }
  .banner-info h2 {
    font-size: 1.45em;
  }
  .banner-info p {
    font-size: 0.875em;
  }
  .banner-info a {
    font-size: 0.875em;
    margin-top: 1em;
    padding: 5px 0px;
    width: 47%;
  }
  .banner {
    min-height: 195px;
  }
  .map-layer {
    min-height: 255px;
  }
  .map iframe{
    min-height: 250px;
  }
  .footer {
    padding: 1.5em 0;
  }
  .header {
    padding: 15px 0;
  }
  .services-section h3 {
    font-size: 2.1em;
  }
  .services-section h4 {
    font-size: 1.5em;
    letter-spacing: 0px;
  }
  .services-section p {
    font-size: 0.95em;
  }
  i.call {
    width: 40px;
    height: 50px;
    background: url('assets/images/img-sprite.png') no-repeat -28px 0px;
    background-size: 450px;
  }
  i.callmsg {
    width: 55px;
    height: 50px;
    background: url('assets/images/img-sprite.png') no-repeat -72px -2px;
    background-size: 450px;
  }
  .arrow2 {
    margin-top: 14px;
  }
  .arrow1 {
    margin-top: 12px;
  }
  i.arrow {
    width: 30px;
    height: 30px;
    background: url('assets/images/img-sprite.png') no-repeat -392px 0px;
    background-size: 500px;
  }
  i.interact {
    width: 100px;
    height: 100px;
    background: url('assets/images/img-sprite.png') no-repeat -125px 2px;
    background-size: 450px;
  }
  .arrow3 {
    margin-top: 40px;
  }
  i.dt {
    width: 65px;
    height: 55px;
    background: url('assets/images/img-sprite.png') no-repeat -231px 0px;
    background-size: 450px;
  }
  .services-section p {
    margin: 8px 0 0px 0;
  }
  i.zoom {
    width: 50px;
    height: 55px;
    background: url('assets/images/img-sprite.png') no-repeat -302px -2px;
    background-size: 450px;
  }
  .icons2 {
    margin-top: 1em;
  }
  .icons {
    margin-top: 1em;
  }
  i.smartphone {
    width: 55px;
    height: 55px;
    background: url('assets/images/img-sprite.png') no-repeat -1px -46px;
    background-size: 450px;
  }
  i.user {
    width: 55px;
    height: 55px;
    background: url('assets/images/img-sprite.png') no-repeat 0px -103px;
    background-size: 450px;
  }
  i.global {
    width: 55px;
    height: 55px;
    background: url('assets/images/img-sprite.png') no-repeat -56px -58px;
    background-size: 450px;
  }
  .services-section {
    padding: 1.5em 0 2em 0;
  }
  .subscribe h3 {
    font-size: 1.5em;
    margin-bottom: 0.4em;
  }
  .subscribe a {
    padding: 5px 10px;
    font-size: 12px;
  }
  .subscribe input[type="text"] {
    padding: 4px;
    font-size: 13px;
  }
  .book img {
    width: 65%;
  }
  .subscribe-section-grids {
    padding-top: 1.1em;
  }
  .subscribe-section {
    min-height: 100px;
  }
  .our-clients-head h3 {
    font-size: 2.1em;
    margin-bottom: 0.6em;
  }
  .contact-form input[type="text"], .contact-form textarea {
    font-size: 12px;
    padding: 10px 10px;
  }
  .contact-form input[type="submit"] {
    font-size: 13px;
    padding: 6px 23px;
    margin-top: 0.3em;
  }
  .contact-form {
    padding: 1.5em 1em;
  }
  .client-text p {
    font-size: 1em;
  }
  .text h4 {
    font-size: 1em;
    margin-bottom: 0px;
  }
  .text p {
    font-size: 0.7em;
  }
  .text {
    padding: 8px 0;
  }
  .description {
    padding: 1em 0 0 0;
    min-height: 153px;
  }
  .nbs-flexisel-nav-right {
    right: 13.5em;
  }
  .nbs-flexisel-nav-left {
    left: 13.5em;
  }
  .testimonials-section-head h3 {
    font-size: 2em;
  }
  .testimonials-section-head {
    margin: 1em 0;
  }
  .testimonials-section {
    min-height: 250px;
  }
  .error-404 h2 {
    font-size: 12em;
  }
  .error-404 {
    padding-top: 4em;
    min-height: 480px;
  }
  .element h4 {
    margin-top: 10px;
    margin-bottom: 3px;
    font-size: 1.12em;
  }
  .element p {
    margin: 0px 0 0 0;
    height: 60px;
    overflow: hidden;
  }
  .why_choose_us h3, .latest-news h3 {
    font-size: 2em;
    letter-spacing: 0px;
  }
  .grid_12 h3 {
    font-size: 2.3em;
  }
  .grid_12 h5 {
    font-size: 1em;
    height: 42px;
  }
  .grid_12 p {
    /*	height: 60px; */
    overflow: hidden;
    line-height: 1.7em;
    font-size: 0.875em;
  }
  .grid_4 p{
    height: 80px;
    overflow: hidden;
    line-height: 1.7em;
    font-size: 0.875em;
    margin: 15px 0 0px 0;
  }
  .grid_4 span {
    margin-top: 5px;
    width: 60px;
    height: 60px;
    font-size: 21px;
    line-height: 60px;
  }
  .services_overview h3 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }
  .services_overview {
    padding: 1.5em 0;
  }
  p.text1 {
    /*		margin-top: -49px;  */
  }
  .content-pagenation {
    margin-top: 2em;
  }
  .contact.about-desc h3 {
    margin-bottom: 0.5em;
    font-size: 2.3em;
  }
  .contact_left h4 {
    font-size: 1.5em;
  }
  p.m_6 {
    margin: 6px 0;
    font-size: 0.9em;
  }
  p.m_7 {
    font-size: 0.9em;
  }
}
@media screen and (max-width: 480px) {
  span.menu {
    width: 21px;
    height: 21px;
    background-size: 21px;
  }
  span.menu {
    margin-top: -27px;
  }
  .banner-info p {
    display: none;
  }
  .banner-info h2 {
    font-size: 1.2em;
  }
  .banner-info a {
    font-size: 0.75em;
    width: 54%;
    margin-top: 1.8em;
  }
  .banner {
    min-height: 170px;
  }
  .header {
    padding: 10px 0;
    border-bottom: 2px solid #900909;
  }
  .subscribe {
    padding: 0;
  }
  .services-section h3 {
    font-size: 1.9em;
  }
  .banner-info {
    width: 48%;
    top: 18%;
    padding: 10px 10px 15px 11px;
  }
  .services-section {
    padding: 1em 0 1.5em 0;
  }
  .subscribe h3 {
    font-size: 1.15em;
  }
  .subscribe a {
    padding: 4px 6px;
    font-size: 12px;
    letter-spacing: 1px;
  }
  .subscribe input[type="text"] {
    padding: 3px;
  }
  .book img {
    width: 75%;
  }
  .subscribe-section {
    min-height: 90px;
  }
  .our-clients {
    padding: 1.5em 0;
  }
  .nbs-flexisel-item img{
    /*	width:38%;  */
  }
  .contact-section-grid {
    width: 100%;
    margin-top: 2em;
  }
  i.smartphone, i.user, i.global {
    margin-bottom: 0.3em;
  }
  .contact-section h3 {
    font-size: 2em;
    margin-bottom: -0.2em;
  }
  .map-layer {
    min-height: 205px;
  }
  .map iframe {
    min-height: 200px;
  }
  .contact-form {
    padding: 1.2em 0em;
  }
  .contact-form input[type="text"], .contact-form textarea {
    margin: 0 0px 10px 0;
    padding: 8px 8px;
  }
  .contact-form textarea {
    height: 6.5em;
  }
  .client-text p{
    height:60px;
    overflow:hidden;
  }
  .nbs-flexisel-nav-right {
    right: 9.5em;
  }
  .nbs-flexisel-nav-left {
    left: 9.5em;
  }
  .description {
    min-height: 137px;
    width: 100%;
  }
  .testimonials-section {
    /*min-height: 220px;*/
    display: none;
  }
  .error-404 h2 {
    font-size: 9em;
  }
  .error-404 p {
    font-size: 1.2em;
  }
  a.b-home {
    padding: 0.6em 2em;
    margin-top: 0.8em;
  }
  .error-404 {
    padding-top: 3em;
    min-height: 380px;
  }
  .grid_12 h3 {
    font-size: 2em;
  }
  .grid_12 h5 {
    font-size: 0.95em;
    height: 39px;
    line-height: 1.5em;
  }
  .who_we_are, .latest-news {
    padding: 1em 0 2em 0;
  }
  .why_choose_us {
    padding: 1.2em 0 2em 0;
  }
  .grid_7 {
    width: 100%;
    padding: 0 50px;
    margin-bottom: 2em;
  }
  .grid_4 {
    width: 100%;
    padding: 0 50px;
    margin-bottom: 2em;
  }
  .service_grid {
    width: 100%;
    margin: 0 0 2em 0;
  }
  .contact.about-desc {
    padding: 1.2em 0;
  }
  .contact.about-desc h3 {
    margin-bottom: 0.4em;
    font-size: 2em;
  }
  .contact_left h4 {
    font-size: 1.35em;
  }
  .head_element span {
    font-size: 1.2em;
  }
  .head_element {
    width: 70px;
    height: 70px;
  }
  .sub h3 {
    font-size: 1.2em;
    letter-spacing: 0px;
    margin-bottom: 0px;
  }
  .sub {
    margin-top: 1.1em;
  }
  h2.with-line {
    font-size: 2.2em;
  }
  .grid_b4 h5 {
    line-height: 1.6em;
    font-size: 0.9em;
  }
  .grid_b4 p {
    line-height: 1.7em;
    margin: 5px 0 15px 0;
    height: 40px;
    overflow: hidden;
  }
  .right_column h2{
    font-size: 1.85em;
  }
  .sub p {
    font-size: 0.875em;
    margin-top: 0px;
  }
  .content-pagenation li a {
    font-size: 0.875em;
    padding: 5px 10px;
  }
  #commentform input[type="text"] {
    padding: 6px 12px;
    width: 75%;
  }
  #commentform textarea {
    padding: 6px 12px;
    width: 75%;
  }
  .comments1 h4 {
    font-size: 1.5em;
  }
  #commentform input[type="submit"] {
    padding: 9px 45px;
  }
  .contact_grid h5 {
    font-size: 1.3em;
  }
  .grid_4 p {
    height: 60px;
  }
  a.readblog {
    font-size: 1.2em;
    letter-spacing: 0px;
  }
}
@media screen and (max-width: 320px) {
  span.menu {
    margin-top: -23px;
  }
  .banner-info h2 {
    font-size: 0.95em;
  }
  .banner-info {
    width: 57%;
    padding: 5px 5px 10px 5px;
  }
  .banner-info a {
    font-size: 0.7em;
    width: 67%;
    margin-top: 1em;
  }
  .banner {
    min-height: 120px;
  }
  .services-section h3 {
    font-size: 1.75em;
  }
  .services-section-grid1-top, .services-section-grid3-top {
    width: 100%;
    margin-right: 0;
    margin-bottom: 2%;
  }
  .services-section-grid1-bottom, .services-section-grid3-bottom {
    width: 100%;
  }
  .book img {
    display: none;
  }
  .subscribe {
    width: 90%;
  }
  .our-clients-head h3 {
    font-size: 1.8em;
  }
  .copyright p {
    font-size: 13px;
  }
  .copyright p a {
    font-size: 15px;
    margin-top: 0.3em;
    display: block;
  }
  .footer {
    padding: 1em 0;
  }
  .map-layer {
    min-height: 155px;
  }
  .map iframe {
    min-height: 150px;
  }
  .contact-left,.contact-right {
    width: 100%;
  }
  .contact-form input[type="submit"] {
    font-size: 12px;
    padding: 5px 20px;
  }
  .contact-form {
    top: 1em;
  }
  .description {
    min-height: 136px;
    width: 62%;
  }
  .member1 {
    width: 38%;
  }
  .client-text p{
    width:100%;
  }
  .nbs-flexisel-nav-right {
    right: 2.5em;
  }
  .nbs-flexisel-nav-left {
    left: 2.5em;
  }
  .grid_4,.grid_7 {
    padding: 0 10px;
    margin-bottom: 1.8em;
  }
  .grid_5 {
    width: 47%;
    margin-left: 0%;
    margin-right: 2%;
  }
  .grid_6 {
    width: 48%;
    margin-right: 0%;
    margin-left: 2%;
  }
  .grid_12 h3 {
    font-size: 1.85em;
  }
  .right_column {
    padding: 0;
  }
  .alpha1 {
    width: 75%;
    margin-right: 0;
    margin-bottom:0.8em;
  }
  .grid_b4 {
    width: 100%;
  }
  .head_element span {
    font-size: 1em;
  }
  .head_element {
    width: 55px;
    height: 55px;
    margin-right: 5px;
    margin-bottom: 10px;
  }
  .sub {
    margin-top: 0.7em;
  }
  h2.with-line {
    font-size: 2em;
  }
  .right_column h2 {
    font-size: 1.8em;
  }
  .blog-section {
    padding: 1em 0 2em 0;
  }
  .contact_left h4 {
    font-size: 1.1em;
  }
  .contact.about-desc h3 {
    font-size: 1.8em;
  }
  p.m_6 {
    height: 67px;
    overflow: hidden;
  }
  .contact-form_grid input[type="submit"] {
    font-size: 16px;
    padding: 9px 0px;
  }
  .contact_address {
    margin: 1.5em 0;
  }
  p.text1 {
    /*		margin-top: -41px;
        height: 136px;
        overflow: hidden;
    */
  }
  .leave h4 {
    font-size: 1.6em;
    letter-spacing: 0px;
    margin-bottom: 5px;
  }
  #commentform input[type="text"],#commentform textarea {
    padding: 5px 10px;
    width: 100%;
  }
  .cmnts-left {
    width: 65%;
  }
  .comments1 {
    margin-top: 1.5em;
  }
  .top-menu ul li a:hover {
    border-bottom: 2px solid #900909;
  }
  .top-menu ul li a.active {
    border-bottom: 2px solid #900909;
  }
  .top-menu ul li a {
    font-size: 0.95em;
    padding: 7px 0px;
    border-bottom: 2px solid #fff;
  }
  a.readblog {
    font-size: 0.92em;
  }
}

#transtable {
  background-color: rgba(0,0,0,0);
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
